import React, {useContext, useEffect, useState} from "react";
import {history, AppStateContext, darkerColor} from "../index";
import {Translation} from "../models/translation";
import { Dropdown, Form } from 'semantic-ui-react'
import {setSelectedLanguage} from "../services/db_service";
import {signin, signup} from "../services/api_service";
import { Popup } from "../widgets/Popup";


export function Login(props: { translations: Translation[] }) {


    const [appState, setAppState] = useContext(AppStateContext);

    const [email, setEmail] = useState ("");
    const [password, setPassword] = useState ("");
    const [error, setError] = useState ("");
 
    useEffect(() => {
      document.body.style.backgroundColor = "#C6E1F9";
      
    }, [0]);


  
    const onSubmit = async () => {
        try {
             const jwt = await signin(email, password);

             if(jwt === undefined) {
                 setError(appState.currentTranslation?.error_message ?? "");
                 return;
             }

             console.log(`jwt is ${jwt}`);
             setAppState({
                 ...appState,
                 jwt
             });
             history.push("/intro");
        } catch(err) {
            console.log(`error is ${err.toString()}`);
            setError(err);
        }
    
    };

    return (
        <div className="splash">
            <Popup translation={ appState.currentTranslation } />
            <div className="login-splash">
                <div className="column margin-top-2">
                    <div className="column-space-between">
                        <h1 className="tutorial_item_login margin-top-2" style={{fontFamily: 'Libre Baskerville, serif', marginBottom: '5rem'}}>{ appState.currentTranslation?.title_registration ?? "" }</h1>
                    <div className="description">
                    <p className="tutorial_item_login_2" style={{fontFamily: 'Libre Baskerville, serif'}}>{ appState.currentTranslation?.credential ?? "" }</p>
                    </div>
                    <div className="tutorial_item_login_3 margin-top-2">
                        <input className="box" type="email" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value) }/>
                    </div>
                    <div className="description">
                        <input className="box" type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value) }/>
                    </div>
                    <button className="input_primary_signup margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ onSubmit }>{ appState.currentTranslation?.botton_login ?? "" }</button>
                    <div className="column margin-top-2">
                    <p>{ error }</p>
                    </div>
                    {/* <p style={{fontFamily: 'Libre Baskerville, serif'}} className="register margin-top-2"></p> */}
                    <button className="input_flat_credential margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/registration")}>{ appState.currentTranslation?.registration ?? "" }</button>
                    <div>
                        <button className="input_gdpr" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/gdpr")}> GDPR Policy </button>
                        |
                        <button className="input_flat" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ () => history.push("/contacts") } >{ appState.currentTranslation?.thanks_contacts ?? "Contatti" }</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}