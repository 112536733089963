import React, {useContext, useEffect, useState} from "react";
import {history, AppStateContext} from "../index";
import {Translation} from "../models/translation";
import { Dropdown } from 'semantic-ui-react'
import {setSelectedLanguage} from "../services/db_service";
import {signup} from "../services/api_service";
import {moodPercentage} from "../services/mood_percentage_service";
import { Popup } from "../widgets/Popup";


export function Registration(props: { translations: Translation[] }) {


    const [appState, setAppState] = useContext(AppStateContext);
    
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState ("");
    const [password, setPassword] = useState ("");
    const [error, setError] = useState ("");
    const [happyCount, setHappyCount] = useState({happy: 0, sad: 0});

    useEffect(() => {
        document.body.style.backgroundColor = "#C6E1F9";

        (async () => {
            const happyCount = await moodPercentage();
            setHappyCount(happyCount);
        }) ()

    }, [0]);


  
    const onSubmit = async () => {
        try {
            const jwt = await signup(email, password);

            if(jwt === undefined) {
                setError( appState.currentTranslation?.error_message ?? "" );
                return;
            }

            setAppState({
                ...appState,
                jwt
            });
            history.push("/intro");
        } catch(err) {
             setError(err);
        }
    };

    return (
        <div className="splash">
            <Popup translation={ appState.currentTranslation } />
            <div className="registration-splash">
                
                <div className="column margin-top-2">
                <div className="lang_selector">
                        <Dropdown className="right" text={appState.currentTranslation?.lang ?? "IT"}>
                            <Dropdown.Menu>
                                {
                                    props.translations.sort(
                                        (a, b) => a.lang.localeCompare(b.lang)
                                    ).map((t) =>
                                        <Dropdown.Item
                                            text={t.lang}
                                            key={t.lang}
                                            onClick={() => {
                                                setSelectedLanguage(t.lang);
                                                setAppState({
                                                    ...appState,
                                                    currentTranslation: props
                                                        .translations
                                                        .filter(tr => tr.lang === t.lang)[0]
                                                });
                                            }}
                                        />)
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="column-space-between">
                        <h1 className="tutorial_item_login margin-top-2" style={{fontFamily: 'Libre Baskerville, serif', marginBottom: '5rem'}}>{ appState.currentTranslation?.title_registration ?? "" }</h1>
                        <div className="description">
                            <p className="tutorial_item_login_2">{ (appState.currentTranslation?.registration_text ?? "" ).split("\n").map(i => <p style={{fontFamily: 'Libre Baskerville, serif'}} key={i}>{i}</p>) }</p>
                            {/* { (appState.currentTranslation?.intro_text ?? "").split("\n").map(i => <p style={{fontFamily: 'Libre Baskerville, serif'}} key={i}>{i}</p>) } */}

                        </div>
                        {happyCount.happy !== 0} 
                        <div className="tutorial_item_login_3 margin-top-2">
                            <div className="margin-top-8 flex-row margin-bottom-2 ">
                                <div>
                                <h3 className="title_img" style={{fontFamily: 'Libre Baskerville, serif'}}>{ appState.currentTranslation?.title_img ?? "" }</h3>
                                <img className="smile-img" src={"smile_happy.svg "}  /><br/>
                                {happyCount.happy}
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <input className="box" type="text" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value) }/>
                        </div>
                        <div className="description margin-top-3">
                            <input className="box" type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value) }/>
                        </div> 
                        <button className="input_primary_signup margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ onSubmit } >{ appState.currentTranslation?.botton_registration ?? "" }</button>
                        <div className="column margin-top-2">
                            <p>{ error }</p>
                        </div>
                            {/* <p style={{fontFamily: 'Libre Baskerville, serif'}} className="register margin-top-2"></p> */}
                        <button className="input_flat_credential margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/login")}>{ appState.currentTranslation?.login_text ?? "Accedi" }</button>
                        <div>
                            <button className="input_gdpr" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/gdpr")}> GDPR Policy </button>
                            |
                            <button className="input_flat" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ () => history.push("/contacts") } >{ appState.currentTranslation?.thanks_contacts ?? "Contatti" }</button>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    );
}