import React, {useContext} from "react";
import { AppStateContext } from "..";
import { Translation } from "../models/translation";

export function Popup(props: { translation: Translation | null }) {

	const closePopup = () => {
		const el = document.getElementById('popup');
		if (el) {
			el.classList.add('hidden');
		}
	}

	return (
		<div id="popup" className="overlay">
			<div className="popup">
					<img className="logo" src="lotus.svg"></img>
					<a className="close" onClick={ closePopup }>&times;</a>
					<div className="content">
							<h2>
									{ props?.translation?.redirect_to_app_title ?? "Profondamente ha fatto un upgrade!" }
							</h2>
							<p>
								{ props?.translation?.redirect_to_app_text ?? "Da qualche giorno è disponibile la nuova App Profondamente, con molti più contenuti per ridurre i momenti di tensione ed aiutarti a gestire lo Stress." }
								{/* TODO: remove hardcoded text and add traslation in BE */}
							</p>
							<h2>
									{ props?.translation?.redirect_to_app_subtitle ?? "Scarica l’App Gratuitamente!" }
							</h2>
							<div className="store-buttons">
									<a href="https://play.google.com/store/apps/details?id=com.uqido.profondamente_mobile">
											<img src="https://lh3.googleusercontent.com/unKTl3n_zDHjBjAMNHovDV6xxAql_1bswAw4vWTlrPjdi_z4QfMc2-XLAHhq34ZBULbHnmEUsXLpy2AjIZZX1dgoj9qgJFea01JZ=w242"></img>
									</a>
									<a href="https://apps.apple.com/us/app/profondamente-relax-sonno/id1558463138">
											<img src="https://lh3.googleusercontent.com/C3GmCSiuOowSXbPix7Gg6R3jbWe_5lu40GvcgSX_g2p6SIvIYKzx11SU97_dmQuGW_SX1_xFNEIyIFo5yW3UB5Ytw7pQEk_KaA=w242"></img>
									</a>
							</div>
					</div>
			</div>
	</div>
	);
}